import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")
  const _directive_Account = _resolveDirective("Account")
  const _directive_PID = _resolveDirective("PID")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    _withDirectives(_createVNode("div", null, null, 512), [
      [_directive_Account],
      [_directive_PID]
    ])
  ], 64))
}